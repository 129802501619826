<template>
  <el-dialog v-model="dialogTableVisible" :before-close="close_visible"  width="600" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :how-close="false">
    <el-form
        ref="el_form"
        status-icon
        :model="submit_list"
        :rules="rules"
    >
      <el-form-item label="添加关键词">
        <div style="display: flex">
          <el-select
              v-model="search_input"
              filterable
              remote
              reserve-keyword
              placeholder="可以是药物、靶点、机构、适应症或其他关键字"
              :remote-method="remoteMethod"
              :loading="loading"
          >
            <el-option
                v-for="item in options.concat(website_options)"
                :key="item.label"
                :value="item.cate ? item.cate + ' - ' + item.label : 'website'  + ' - ' + item.website_id"
                :label="item.label ? item.label : name_website(item.website_id)"
            >
              <div class="d-flex" style="align-items: center">
                <el-text type="danger">{{item.cate ? label_name[item.cate] : label_name['website']}}</el-text>
                ·
                <span>{{ item.label ? item.label : name_website(item.website_id) }}</span>
              </div>
            </el-option>
          </el-select>
          <el-button @click="add_label(search_input)" style="margin-left: 12px" type="primary">添加</el-button>
        </div>
      </el-form-item>
      <el-form-item label="分类标签" prop="submit_list">
        <el-checkbox-group v-model="submit_list.category_list">
          <el-checkbox v-for="item in category_list" :label="item" :value="item" />
<!--          <el-checkbox label="临床" value="临床" />-->
<!--          <el-checkbox label="交易" value="交易" />-->
<!--          <el-checkbox label="其他" value="其他" />-->
        </el-checkbox-group>
      </el-form-item>
      <div v-for="(items,key) in sort_list" :key="key">
        <el-form-item v-if="submit_list[items].length && key !== 0" :label="items === 'website_list' ? label_name[items.split('_')[0]] : label_name[items.split('_')[0]] + '标签'">
          <el-tag
              v-for="item in submit_list[items]"
              style="margin-right: 3px;margin-bottom: 5px"
              closable
              :disable-transitions="false"
              @close="handleClose(item,items)"
              effect="dark"
          >{{items === 'website_list' ? name_website(item) : item }}</el-tag>
        </el-form-item>
      </div>
      <el-form-item label="发送给" prop="mailbox_textarea">
        <el-checkbox-group v-if="email" v-model="is_oneself">
          <el-checkbox :label="email+'(自己)'" :value="email" />
        </el-checkbox-group>
        <el-input
            v-model="mailbox_textarea"
            :rows="2"
            type="textarea"
            placeholder="请输入收件人邮箱地址，多个邮箱之间请回车分隔"
        />
      </el-form-item>
      <el-form-item label="提醒频次">
        <el-row>
          <el-col :span="12">
            <el-select v-model="frequency_value" @change="get_date_option" placeholder="Select">
              <el-option
                  v-for="item in frequency_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col v-if="frequency_value !== 'daily'" :span="12">
            <el-select v-model="date_value" placeholder="Select">
              <el-option
                  v-for="item in date_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="订阅主题" prop="subject_input">
        <el-input v-model="subject_input" placeholder="为邮件主题" />
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button @click="close_visible">取消</el-button>
      <el-button @click="subscribe_create" type="primary">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {create_subscribe, labels_fuzz, update_subscribe, website_fuzz} from "@/api/data.js";
import {ref, defineProps, defineEmits, reactive, onMounted} from "vue";
import {
  frequency_option,
  weekly_option,
  biweekly_option,
  monthly_option,
  label_name,
  sort_list, category_list
} from "../common/results_data.js";

const props = defineProps({
  title: {
    type: String,
    default: '新增订阅'
  },
  label_list: Object,
  query_list: Object,
  tos: Array,
  subject: String,
  schedule: Object,
  sub_id: String,
  website_name: {
    type: Array,
    default: ()=> []
  }
});
const el_form = ref(null)
const email = localStorage.getItem('email');
const dialogTableVisible = ref(true)
const search_input = ref('')
const options = ref([])
const website_options = ref([])
const loading = ref(false)
const subject_input = ref('')
const mailbox_textarea = ref('')
const frequency_value = ref('weekly')
const date_value = ref('{"first":["monday"]}')
const date_option = ref(weekly_option)
const is_oneself = ref(email ? [email] : [])
const query_list =  reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
  website_list: []
})
const submit_list = reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
  website_list: []
})
const is_submit_list = (rule, value, callback) => {
  const label_count =
      submit_list.category_list.length +
      submit_list.common_list.length +
      submit_list.drug_list.length +
      submit_list.target_list.length +
      submit_list.indication_list.length +
      submit_list.org_list.length +
      submit_list.website_list.length
  if (label_count) {
    callback()
  } else {
    callback(new Error('至少选择一个标签'))
  }
}
const is_mailbox_textarea = (rule, value, callback) => {
  if (is_oneself.value.length) {
    callback()
  } else {
    if(mailbox_textarea.value){
      callback()
    }
    callback(new Error('请添加收件人'))
  }
}
const is_subject_input = (rule, value, callback) => {
  if (subject_input.value) {
    callback()
  } else {
    callback(new Error('请输入邮件主题'))
  }
}
const name_website = (id) => {
  let name = ''
  if(props.website_name.length){
    props.website_name.forEach((val) => {
      if(val.website_id === id){
        console.log(val)
        name = val.website_name
      }
    })
  } else {
    name = id
  }
  return name
}
const rules = reactive({
  submit_list: [{ validator: is_submit_list, trigger: 'submit', required: true }],
  mailbox_textarea: [{ validator: is_mailbox_textarea, trigger: 'submit', required: true }],
  subject_input: [{ validator: is_subject_input, trigger: 'submit', required: true }],
})
const remoteMethod = (query) => {
  if (query) {
    loading.value = true
    const arr = []
    labels_fuzz(query)
        .then(res =>{
          loading.value = false
          options.value = res.data.labels
        })
    website_fuzz(query)
        .then(res => {
          website_options.value = res.data.result.infos
        })

  } else {
    options.value = []
    website_options.value = []
  }
}

const add_label = (data) =>{
  // console.log(data)
  // console.log(data.split(' - ')[0] + '_list')
  // console.log(data.split(' - ')[1])
  if(data){
    if(!submit_list[data.split(' - ')[0] + '_list'].includes(data)){
      submit_list[data.split(' - ')[0] + '_list'].push(data.split(' - ')[1])
    }
  } else {
  }
  search_input.value = ''
}

const emit = defineEmits(['send-visible']);

const close_visible = (val = false) =>{
  emit('send-visible', {
    visible: false,
  });
}
const subscribe_create = ()=>{
  el_form.value.validate(valid => {
    if (valid) {
      let strArray = []
      if(mailbox_textarea.value){
        strArray = mailbox_textarea.value.split('\n')
      }
      const newArr = [...is_oneself.value, ...strArray]
      const schedule = {
        type: frequency_value.value,
        when: frequency_value.value === 'daily' ? {} : JSON.parse(date_value.value)
      }
      var newArray = submit_list.website_list.map(function(item) {
        return { website_id: item };
      });
      // console.log(schedule)
      if(props.sub_id){
        update_subscribe(JSON.stringify({
          id: props.sub_id,
          subject: subject_input.value,
          tos: newArr,
          schedule,
          status: 1,
          category: submit_list.category_list.concat(query_list.category_list),
          common_labels: submit_list.common_list.concat(query_list.common_list),
          drug_labels: submit_list.drug_list.concat(query_list.drug_list),
          target_labels: submit_list.target_list.concat(query_list.target_list),
          indication_labels: submit_list.indication_list.concat(query_list.indication_list),
          org_labels: submit_list.org_list.concat(query_list.org_list),
          websites: newArray,
        }))
            .then(res=>{
              close_visible(true)
            })
            .catch(err=> {
              ElMessage.error('订阅失败')
            })
      } else {
        create_subscribe(JSON.stringify({
          subject: subject_input.value,
          tos: newArr,
          schedule,
          status: 1,
          category: submit_list.category_list.concat(query_list.category_list),
          common_labels: submit_list.common_list.concat(query_list.common_list),
          drug_labels: submit_list.drug_list.concat(query_list.drug_list),
          target_labels: submit_list.target_list.concat(query_list.target_list),
          indication_labels: submit_list.indication_list.concat(query_list.indication_list),
          org_labels: submit_list.org_list.concat(query_list.org_list),
          websites: newArray,
        }))
            .then(res=>{
              close_visible(true)
            })
            .catch(err=> {
              ElMessage.error('订阅失败')
            })
      }
    } else {
      ElMessage.error('请正确填充参数')
    }
  })
}
const get_date_option = (val) => {
  if(frequency_value.value === 'weekly'){
    date_option.value = weekly_option
    date_value.value = date_option.value[0].value
  } else if(frequency_value.value === 'biweekly') {
    date_option.value = biweekly_option
    date_value.value = date_option.value[0].value
  } else if(frequency_value.value === 'monthly'){
    date_option.value = monthly_option
    date_value.value = date_option.value[0].value
  }
}
const handleClose = (val, name) => {
  submit_list[name] = submit_list[name].filter(item => item !== val)
}

onMounted(()=>{
  if(props.label_list){
    Object.assign(submit_list, {...props.label_list})
  }
  if(props.query_list){
    Object.assign(query_list, {...props.query_list})
  }
  if(props.tos?.length){
    if(email){
      if(props.tos.includes(email)){
        is_oneself.value = [email]
      } else {
        is_oneself.value = []
      }
      let arr = props.tos.filter(val => val !== email)
      if(arr.length){
        mailbox_textarea.value = arr.join('\n')
      }
    } else {
      mailbox_textarea.value = props.tos.join('\n')
    }
  }
  if(props.subject){
    subject_input.value = props.subject
  }
  if(props.schedule){
    frequency_value.value = props.schedule.type
    if(frequency_value.value === 'weekly'){
      date_option.value = weekly_option
    } else if(frequency_value.value === 'biweekly') {
      date_option.value = biweekly_option
    } else if(frequency_value.value === 'monthly'){
      date_option.value = monthly_option
    }
    date_value.value = JSON.stringify(props.schedule.when)
  }
})
</script>

<style scoped>
.title{
  margin-top: 20px;
  margin-bottom: 10px;
}
:deep(.el-form-item){
  display: inline;
}
:deep(.el-form-item__content) > div{
  width: 100%;
  margin-bottom: 13px;
}
:deep(.el-form-item__error){
  top: unset;
  bottom: -16px;
}
</style>