<template>
  <header>
    <div class="title">
      <h3 style="width: 25%;text-align: left;display: flex;align-items: center;">
        <img v-if="route_name === 'home'" src="@/assets/favicon_white.png" alt="favicon" class="left_nav_title_img" />
        <img v-else src="@/assets/favicon.png" alt="favicon" class="left_nav_title_img" />
        齐鲁情报数据库
      </h3>
      <el-select
          v-if="top_serch"
          v-model="search_input"
          filterable
          remote
          reserve-keyword
          clearable
          @change="news_search"
          placeholder="Please input a keyword"
          :remote-method="remoteMethod"
          :loading="loading"
          style="width: 50%"
      >
        <el-option
            v-for="item in options"
            :key="item.label"
            :value="item.cate + ' - ' + item.label"
            :label="item.label"
        >
          <div class="d-flex" style="align-items: center">
            <el-text type="danger">{{label_name[item.cate]}}</el-text>
            ·
            <span>{{ item.label }}</span>
          </div>
        </el-option>
      </el-select>
      <div style="width: 25%;text-align: right">
        <User_avatar></User_avatar>
      </div>
    </div>
    <el-divider />
  </header>
  <div id="main">
    <el-menu
        v-if="left_menu"
        :default-active="props.tab_name"
    >
      <el-menu-item @click="router_push({ name: 'home' })" index="1">
        <span class="custom-tabs-label">
            <el-icon style="margin: 0" size="30"><House /></el-icon>
            <span>首页</span>
          </span>
      </el-menu-item>
      <el-menu-item @click="router_push({ name: 'advisory' })" index="2">
        <span class="custom-tabs-label">
            <el-icon style="margin: 0" size="30"><Message /></el-icon>
            <span>资讯库</span>
          </span>
      </el-menu-item>
      <el-menu-item @click="router_push({ name: 'subscription' })" index="3">
        <span class="custom-tabs-label">
            <el-icon style="margin: 0" size="30"><ShoppingCart /></el-icon>
            <span>订阅</span>
          </span>
      </el-menu-item>
    </el-menu>
    <div class="pane_main">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted} from "vue";
import { Search } from '@element-plus/icons-vue'
import User_avatar from "@/components/users/user_avatar.vue";
import router from "@/router/index.js";
import {labels_fuzz} from "@/api/data.js";
import {label_name} from "@/components/common/results_data"
import { useRoute } from 'vue-router'
import {requireImg} from "@/components/common/utils.js";

const props = defineProps({
  tab_name: String,
  search_input: String,
  left_menu: {
    type: Boolean,
    default: true
  },
  top_serch: {
    type: Boolean,
    default: true
  },
});
const route = useRoute()

const route_name = route.name
const router_push = command => {
  router.push(command)
}

const search_input = ref(props.search_input ? props.search_input.split(' - ')[1] : '')
const options = ref([])
const loading = ref(false)
const is_login = localStorage.getItem('access_token');
const remoteMethod = (query) => {
  if (query) {
    loading.value = true
    labels_fuzz(query)
        .then(res =>{
          loading.value = false
          options.value = res.data.labels
        })
  } else {
    options.value = []
  }
}
const emit = defineEmits(['send-label']);

const news_search = (val) =>{
  // console.log(val)
  router_push({ name: 'advisory', query: { search: val } })
  emit('send-label', val);
}
onMounted(()=>{
  if(route_name === 'home')
  document.querySelector('.title').classList.add('home')
})
</script>

<style scoped>
.pane_main{
  width: 94%;
  height: 100%;
  text-align: left;
}
#main{
  display: flex;
  height: calc(100vh - 60px);
}
.home{
  background-color: rgb(2,97,238);
}
.home h3{
  color: #ffffff;
}
.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 40px;
}
.custom-tabs-label{
  text-align: center;
  line-height: normal;
  width: 100%;
  //height: 50px;
}
.custom-tabs-label > span {
  display: block;
  font-size: 12px;
}
:deep(.el-menu-item){
  height: 70px;
}
:deep(){
  margin: 0;
}
.el-divider--horizontal{
  margin: 0;
}
.el-tabs{
  width: 100%;
}
.el-tab-pane{
  height: 100%;
}
.left_nav_title_img{
  width: 50px;
  margin-right: 20px;
}
</style>