<template>
  <App_frame tab_name="2" v-model:search_input="search" @send-label="news_fuzzy_query">
    <div style="text-align: left;" class="d_flex">
      <div class="left_main_bar">
        <el-button @click="collapse_hidden" class="left_bold" :icon="ArrowLeftBold" circle />
        <el-button @click="collapse_show" class="right_bold d_none" :icon="ArrowRightBold" circle />
        <el-button v-if="!deepEqual(check_list,submit_list)" @click="news_check" class="filter_btn" type="primary">过<br/>滤</el-button>
        <div class="o_hidden" style="width: 95%;">
          <el-input
              v-model="search_input"
              @change="check_labels"
              placeholder="Please Input"
              :suffix-icon="Search"
          />
        </div>
        <div class="o_hidden" style="margin: 10px 0;">
          <span style="color: #999;font-size: 12px">过滤项</span>
        </div>
        <div class="o_hidden">
          <el-scrollbar height="calc(100vh - 150px)">
            <el-collapse v-model="collapse_value">
              <el-collapse-item title="发布时间" name="1">
                <el-radio-group size="small" v-model="date_type" @change="clear_date">
                  <el-radio style="width: 100%" value="any">任意时间</el-radio>
                  <el-radio style="width: 100%" value="last-week">最近一周</el-radio>
                  <el-radio style="width: 100%" value="range">自定义时间</el-radio>
                </el-radio-group>
              </el-collapse-item>
              <el-date-picker
                  @change="upload_news()"
                  :disabled="date_type !== 'range'"
                  v-model="date_list"
                  value-format="YYYY-MM-DD"
                  type="daterange"
                  size="small"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 95%"
              />
              <el-collapse-item title="分类标签" name="2">
                <div class="w-100" style="overflow-x: hidden;">
                  <el-checkbox-group v-if="lists.category_list.length" size="small" v-model="check_list.category_list">
                    <el-checkbox v-for="item in lists.category_list" :label="item.label" :value="item.label" :disabled="dis_list(item.label,'category_list')" class="w-100">
                      <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                        <span class="ellipsis">{{category_name(item.label)}}</span>
                        <span v-if="item.count" style="color: #909399">{{item.count}}</span>
                      </div>
                      <el-progress v-if="item.count && max_count(lists.category_list) !== 1" :percentage="item.count / max_count(lists.category_list) * 100" :show-text="false" stroke-width="3" />
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-else style="text-align: center;">
                    <el-text size="small" type="info">未检索到标签</el-text>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="数据来源" name="3">
                <el-scrollbar max-height="300px" always>
                  <div class="w-100" style="overflow-x: hidden;">
                    <el-checkbox-group v-if="lists.website_list.length" size="small" v-model="check_list.website_list">
                      <el-checkbox v-for="item in lists.website_list" :label="item.website_name" :value="item.website_id" :disabled="dis_list(item.label,'website_list')" class="w-100">
                        <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                          <span class="ellipsis">{{item.website_name}}</span>
                          <span v-if="item.news_count" style="color: #909399">{{item.news_count}}</span>
                        </div>
                        <el-progress v-if="item.news_count && max_count(lists.website_list) !== 1" :percentage="item.news_count / max_count(lists.website_list) * 100" :show-text="false" stroke-width="3" />
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-else style="text-align: center;">
                      <el-text size="small" type="info">未检索到标签</el-text>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="d_flex" style="justify-content: center">
                  <el-button v-if="lists.website_list.length >= 10 && !show_more.website_labels" @click="more_label('website_labels',show_more.website_labels)" size="small">显示更多</el-button>
                  <el-button v-if="show_more.website_labels" @click="more_label('website_labels',show_more.website_labels)" size="small">收起</el-button>
                </div>
              </el-collapse-item>
              <el-collapse-item title="通用标签" name="4">
                <el-scrollbar max-height="300px" always>
                  <div class="w-100" style="overflow-x: hidden">
                    <el-checkbox-group v-if="lists.common_list.length" size="small" v-model="check_list.common_list">
                      <el-checkbox v-for="item in lists.common_list" :label="item.label" :value="item.label" :disabled="dis_list(item.label,'common_list')" class="w-100">
                        <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                          <span class="ellipsis">{{item.label}}</span>
                          <span v-if="item.count" style="color: #909399">{{item.count}}</span>
                        </div>
                        <el-progress v-if="item.count && max_count(lists.common_list) !== 1" :percentage="item.count / max_count(lists.common_list) * 100" :show-text="false" stroke-width="3" />
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-else style="text-align: center;">
                      <el-text size="small" type="info">未检索到标签</el-text>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="d_flex" style="justify-content: center">
                  <el-button v-if="lists.common_list.length >= 10 && !show_more.common_labels" @click="more_label('common_labels',show_more.common_labels)" size="small">显示更多</el-button>
                  <el-button v-if="show_more.common_labels" @click="more_label('common_labels',show_more.common_labels)" size="small">收起</el-button>
                </div>
              </el-collapse-item>
              <el-collapse-item title="机构" name="5">
                <el-scrollbar max-height="300px" always>
                  <div class="w-100" style="overflow-x: hidden">
                    <el-checkbox-group v-if="lists.org_list.length" size="small" v-model="check_list.org_list">
                      <el-checkbox v-for="item in lists.org_list" :label="item.label" :value="item.label" :disabled="dis_list(item.label,'org_list')" class="w-100">
                        <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                          <span class="ellipsis">{{item.label}}</span>
                          <span v-if="item.count" style="color: #909399">{{item.count}}</span>
                        </div>
                        <el-progress v-if="item.count && max_count(lists.org_list) !== 1" :percentage="item.count / max_count(lists.org_list) * 100" :show-text="false" stroke-width="3" />
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-else style="text-align: center;">
                      <el-text size="small" type="info">未检索到标签</el-text>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="d_flex" style="justify-content: center">
                  <el-button v-if="lists.org_list.length >= 10 && !show_more.org_labels" @click="more_label('org_labels',show_more.org_labels)" size="small">显示更多</el-button>
                  <el-button v-if="show_more.org_labels" @click="more_label('org_labels',show_more.org_labels)" size="small">收起</el-button>
                </div>
              </el-collapse-item>
              <el-collapse-item title="药物" name="6">
                <el-scrollbar max-height="300px" always>
                  <div class="w-100" style="overflow-x: hidden">
                    <el-checkbox-group v-if="lists.drug_list.length" size="small" v-model="check_list.drug_list">
                      <el-checkbox v-for="item in lists.drug_list" :label="item.label" :value="item.label" :disabled="dis_list(item.label,'drug_list')" class="w-100">
                        <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                          <span class="ellipsis">{{item.label}}</span>
                          <span v-if="item.count" style="color: #909399">{{item.count}}</span>
                        </div>
                        <el-progress v-if="item.count && max_count(lists.drug_list) !== 1" :percentage="item.count / max_count(lists.drug_list) * 100" :show-text="false" stroke-width="3" />
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-else style="text-align: center;">
                      <el-text size="small" type="info">未检索到标签</el-text>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="d_flex" style="justify-content: center">
                  <el-button v-if="lists.drug_list.length >= 10 && !show_more.drug_labels" @click="more_label('drug_labels',show_more.drug_labels)" size="small">显示更多</el-button>
                  <el-button v-if="show_more.drug_labels" @click="more_label('drug_labels',show_more.drug_labels)" size="small">收起</el-button>
                </div>
              </el-collapse-item>
              <el-collapse-item title="靶点" name="7">
                <el-scrollbar max-height="300px" always>
                  <div class="w-100" style="overflow-x: hidden">
                    <el-checkbox-group v-if="lists.target_list.length" size="small" v-model="check_list.target_list">
                      <el-checkbox v-for="item in lists.target_list" :label="item.label" :value="item.label" :disabled="dis_list(item.label,'target_list')" class="w-100">
                        <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                          <span class="ellipsis">{{item.label}}</span>
                          <span v-if="item.count" style="color: #909399">{{item.count}}</span>
                        </div>
                        <el-progress v-if="item.count && max_count(lists.target_list) !== 1" :percentage="item.count / max_count(lists.target_list) * 100" :show-text="false" stroke-width="3" />
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-else style="text-align: center;">
                      <el-text size="small" type="info">未检索到标签</el-text>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="d_flex" style="justify-content: center">
                  <el-button v-if="lists.target_list.length >= 10 && !show_more.target_labels" @click="more_label('target_labels',show_more.target_labels)" size="small">显示更多</el-button>
                  <el-button v-if="show_more.target_labels" @click="more_label('target_labels',show_more.target_labels)" size="small">收起</el-button>
                </div>
              </el-collapse-item>
              <el-collapse-item title="适应症" name="8">
                <el-scrollbar max-height="300px" always>
                  <div style="overflow-x: hidden;">
                    <el-checkbox-group v-if="lists.indication_list.length" size="small" v-model="check_list.indication_list">
                      <el-checkbox v-for="item in lists.indication_list" :label="item.label" :value="item.label" :disabled="dis_list(item.label,'indication_list')" class="w-100">
                        <div style="display:flex;justify-content: space-between;font-size: 12px;margin-bottom: 5px">
                          <span class="ellipsis">{{item.label}}</span>
                          <span v-if="item.count" style="color: #909399">{{item.count}}</span>
                        </div>
                        <el-progress v-if="item.count && max_count(lists.indication_list) !== 1" :percentage="item.count / max_count(lists.indication_list) * 100" :show-text="false" stroke-width="3" />
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-else style="text-align: center;">
                      <el-text size="small" type="info">未检索到标签</el-text>
                    </div>
                  </div>
                </el-scrollbar>
                <div class="d_flex" style="justify-content: center">
                  <el-button v-if="lists.indication_list.length >= 10 && !show_more.indication_labels" @click="more_label('indication_labels',show_more.indication_labels)" size="small">显示更多</el-button>
                  <el-button v-if="show_more.indication_labels" @click="more_label('indication_labels',show_more.indication_labels)" size="small">收起</el-button>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-scrollbar>
        </div>
      </div>
      <div class="flex_1">
        <div v-if="label_count">
          <p style="margin-left: 20px;display: flex;align-items: center;font-size: 14px">
            过滤条件：
            <el-button size="small" @click="clear_all" type="primary" link>清除所有过滤条件</el-button>
            <el-button size="small" @click="cearte_dialog" type="danger" link>订阅</el-button>
          </p>
          <div style="margin-left: 20px;display: flex;align-items: center;">
<!--            <span v-if="submit_list.website_list.length" style="margin-right: 20px;font-size: 14px">-->
<!--              数据来源：-->
<!--              <el-tag-->
<!--                  v-for="item in submit_list.website_list"-->
<!--                  closable-->
<!--                  size="small"-->
<!--                  :disable-transitions="false"-->
<!--                  @close="handleClose(item,'website_list')"-->
<!--                  effect="dark"-->
<!--              >{{name_website(item)}}</el-tag>-->
<!--            </span>-->
            <div v-for="items in sort_list">
              <span v-if="submit_list[items].length" style="margin-right: 20px;font-size: 14px">
                {{items === 'category_list' ? '分类标签' : label_name[items.split('_')[0]] + '标签'}}：
                <el-tag
                    v-for="item in submit_list[items]"
                    closable
                    size="small"
                    :disable-transitions="false"
                    @close="handleClose(item,items)"
                    effect="dark"
                >{{items === 'website_list' ? name_website(item) : item }}</el-tag>
              </span>
            </div>
          </div>
        </div>
        <p style="margin-top: 4px;margin-left: 20px;font-size: 14px">
          <span style="font-size: 16px;font-weight: bolder">{{news_count}}</span>
          条新闻
        </p>
        <el-divider />
        <p style="margin-left: 20px;display: flex;align-items: center;font-size: 14px">
          发布日期 |
          <el-dropdown size="small"class="hand-cursor" style="margin-left: 5px;" trigger="click" @command="deopdown_command">
            <span style="display: flex;align-items: center;font-size: 14px">
              {{news_sort}}
              <el-icon style="margin-left: 3px;"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu size="small">
                <el-dropdown-item :command="0">倒序</el-dropdown-item>
                <el-dropdown-item :command="1">正序</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </p>
        <el-divider />
        <div>
          <el-scrollbar ref="scrollbar" :height="`calc(100vh - ${label_count ? '235px' : '190px'})`">
            <div>
              <el-card shadow="never" v-for="item in news_data" style="margin: 10px 10px 5px 20px">
                <div style="position: relative">
                  <div style="position: absolute;right: 0;bottom: 0">
                    <el-button @click="feedback(item)" type="primary" size="small">反馈</el-button>
                    <el-popover placement="bottom-end" :width="750" trigger="click">
<!--                      :visible="show_history"-->
                      <template #reference>
                        <el-button @click="get_history(item.id)" size="small" circle>
                          <svg_temp name="history"></svg_temp>
                        </el-button>
                      </template>
                      <div>
<!--                        <p style="text-align: right">-->
<!--                          <el-button size="small" circle >-->
<!--                            <el-icon><Close /></el-icon>-->
<!--                          </el-button>-->
<!--                        </p>-->
                        <el-table :data="history_data" height="300px">
                          <el-table-column width="100" property="label_type" label="反馈项">
                            <template v-slot="{ row }">
                              {{label_type?.[row.label_type]}}
                            </template>
                          </el-table-column>
                          <el-table-column width="100" property="action_type" label="反馈类别">
                            <template v-slot="{ row }">
                              {{action_type?.[row.action_type]}}
                            </template>
                          </el-table-column>
                          <el-table-column width="100" property="raw_label" label="修改前" />
                          <el-table-column width="100" property="after_label" label="修改后" />
                          <el-table-column width="100" property="deleted" label="删除" />
                          <el-table-column width="100" property="added" label="增加" />
                          <el-table-column width="120" property="created_at" label="反馈时间"  show-overflow-tooltip>
<!--                            {{time_from_now}}-->
                            <template v-slot="{ row }">
                              {{time_from_now(row.created_at)}}
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-popover>
                  </div>
                  <p>
                    <span style="font-size: 12px">{{time_from_now(item.date)}}</span>
                    ·
                    <el-tag type="info" :color="tag_type(item.category)" size="small" effect="dark">{{category_name(item.category)}}</el-tag>
                    ·
                    <el-text class="hand-cursor" @click="item.website_catgory === 'wechat' ? '' : href_url(item.website_url)" size="small" type="primary">{{item.website_name}}</el-text>
                  </p>
                  <!--                <h4 class="hand-cursor" @click="item.website_catgory === 'wechat' ? href_url(item.page_url) : router_url(item.id)" style="margin: 10px 0">{{item.title.split('%%%%')[0]}}</h4>-->
                  <h4 style="margin: 10px 0">
                    <span class="hand-cursor" @click="href_url(item.page_url)">{{item.title.split('%%%%')[0]}}</span>
                  </h4>
                  <el-text size="small" type="info">
                    {{item.summary}}
                  </el-text>
                  <p style="margin: 10px 0">
                    <el-tag v-for="label in item.common_labels" :key="label" :title="label" type="success" size="small" effect="light">{{label}}</el-tag>
                  </p>
                </div>
                <el-divider />
                <p class="tab">
                  <el-text size="small" style="width: 100px;display: inline-block;" type="info">提及到的实体</el-text>
                </p>
                <p class="tab">
                  <el-text size="small" style="width: 100px;display: inline-block;" type="info">机构：</el-text>
                  <span v-if="item.org_labels.length">
                  <el-tag size="small" v-for="label in item.org_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
                </span>
                  <span v-else>-</span>
                </p>
                <p class="tab">
                  <el-text size="small" style="width: 100px;display: inline-block;" type="info">适应症：</el-text>
                  <span v-if="item.indication_labels.length">
                  <el-tag size="small" v-for="label in item.indication_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
                </span>
                  <span v-else>-</span>
                </p>
                <p class="tab">
                  <el-text size="small" style="width: 100px;display: inline-block;" type="info">靶点：</el-text>
                  <span v-if="item.target_labels.length">
                  <el-tag size="small" v-for="label in item.target_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
                </span>
                  <span v-else>-</span>
                </p>
                <p class="tab">
                  <el-text size="small" style="width: 100px;display: inline-block;" type="info">药物：</el-text>
                  <span v-if="item.drug_labels.length">
                  <el-tag size="small" v-for="label in item.drug_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
                </span>
                  <span v-else>-</span>
                </p>
              </el-card>
            </div>
          </el-scrollbar>
        </div>
        <div class="pagination">
          <el-pagination :current-page="current_page" :page-size="page_size" :page-sizes="[20, 40, 60, 80, 100]"
                         :total="news_count" align="center" layout="total, sizes, prev, pager, next, jumper" size="small"
                         @size-change="page_size_change" @current-change="current_page_change">
          </el-pagination>
        </div>
      </div>
      <New_subscription v-if="dialogTableVisible" v-model:isdialog="dialogTableVisible" :label_list="submit_list" :query_list="query_list" :website_name="website_name_list" @send-visible="change_visibel" />
      <News_feedback v-if="feedback_visible" v-model:isdialog="feedback_visible" :old_data="feedback_data" @send-visible="feedback_visibel"></News_feedback>
    </div>
  </App_frame>
</template>

<script setup>
import {onMounted, ref, reactive, watch} from 'vue'
import App_frame from "@/components/layout/app_frame.vue";
import {Search, ArrowLeftBold, ArrowRightBold, RefreshLeft} from "@element-plus/icons-vue";
import {
  get_subscribe_info,
  label_history,
  labels_fuzz,
  labels_hist,
  news_filter,
  website_fuzz,
  website_hist
} from "@/api/data.js";
import {category_list, label_name, sort_label, sort_list} from "@/components/common/results_data.js";
import {dayjs} from "element-plus";
import relativeTime from "dayjs/plugin/relativeTime.js";
import New_subscription from "@/components/results/new_subscription.vue";
import {useRoute} from "vue-router";
import router from "@/router/index.js";
import News_feedback from "@/components/results/news_feedback.vue";
import {label_type, action_type} from "@/components/common/feedback_option.js";
import Svg_temp from "@/components/icon/svg_temp.vue";

const search_input = ref()
const collapse_value = ref(['1', '2', '3', '4', '5', '6', '7', '8'])
const label_count = ref(0)
const date_type = ref('any')
const date_list = ref([])
const dialogTableVisible = ref(false)
const route = useRoute()
const search = ref(route.query.search ? route.query.search : '')
const sub_id = ref(route.query.sub ? route.query.sub : '')
const scrollbar = ref(null)
// console.log(search)
const lists = reactive({
  date_list: [],
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
  website_list: []
})
const check_list = reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
  website_list: []
})
const submit_list = reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
  website_list: []
})
const query_list = reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
})
// const data_list = ref([])
// const category_list = ref([])
// const common_list = ref([])
// const drug_list = ref([])
// const target_list = ref([])
// const indication_list = ref([])
const show_more = reactive({
  common_labels: false,
  drug_labels: false,
  target_labels: false,
  indication_labels: false,
  org_labels: false,
  website_labels: false
})
const org_list = ref([])
const news_data = ref([])
const news_count = ref()
const news_sort = ref('倒序')
const current_page = ref(1)
const page_size = ref(20)
const feedback_visible = ref(false)
const feedback_data = ref()
const show_history = ref(false)
const history_data = ref([])
const website_name_list = ref([])

watch(submit_list,()=>{
  // console.log(label_count.value)
  label_count.value =
      submit_list.category_list.length +
      submit_list.common_list.length +
      submit_list.drug_list.length +
      submit_list.target_list.length +
      submit_list.indication_list.length +
      submit_list.org_list.length +
      submit_list.website_list.length
},{
  deep: true
})
const collapse_hidden = () =>{
  document.querySelector('.left_main_bar').classList.add('hidden')
  document.querySelector('.right_bold').classList.remove('d_none')
  document.querySelector('.left_bold').classList.add('d_none')
}
const collapse_show = () =>{
  document.querySelector('.left_main_bar').classList.remove('hidden')
  document.querySelector('.right_bold').classList.add('d_none')
  document.querySelector('.left_bold').classList.remove('d_none')
}
const max_count = (arr)=>{
  if(arr[0].count){
    return arr.reduce((max, obj) => (obj.count > max ? obj.count : max), arr[0].count)
  } else {
    return arr.reduce((max, obj) => (obj.news_count > max ? obj.news_count : max), arr[0].news_count)
  }
}
const more_label = (label, show) => {
  show_more[label] = !show_more[label]
  if(show){
    get_labels(label)
  } else {
    get_labels(label,1,999)
  }
}
const get_labels = (label,page = 1, size = 10)=>{
  if(label === 'category'){
    labels_hist(label, page, size, search.value)
        .then(res=>{
          lists.category_list = res.data.infos
        })
  } else if(label === 'common_labels'){
    labels_hist(label, page, size, search.value)
        .then(res=>{
          lists.common_list = res.data.infos
        })
  } else if(label === 'drug_labels'){
    labels_hist(label, page, size, search.value)
        .then(res=>{
          lists.drug_list = res.data.infos
        })
  } else if(label === 'target_labels'){
    labels_hist(label, page, size, search.value)
        .then(res=>{
          lists.target_list = res.data.infos
        })
  } else if(label === 'indication_labels'){
    labels_hist(label, page, size, search.value)
        .then(res=>{
          lists.indication_list = res.data.infos
        })
  } else if(label === 'org_labels'){
    labels_hist(label, page, size, search.value)
        .then(res=>{
          lists.org_list = res.data.infos
        })
  } else if(label === 'website_labels'){
    website_hist(label, page, size, search.value)
        .then(res =>{
          lists.website_list = res.data.result.infos
        })
  } else {
    const arr = []
    for (let i = 0; i < sort_label.length; i++) {
      if(sort_label[i] !== 'website_list') {
        arr.push(labels_hist(sort_label[i], page, size, search.value))
      }
    }
    Promise.all(arr)
        .then(res => {
          console.log(res)
          res.splice(1, 0, [])
          for (let i = 0; i < sort_list.length; i++) {
            // console.log(sort_list[i])
            if(sort_list[i] !== 'website_list'){
            //   console.log(res[i]?.data)
              lists[sort_list[i]] = res[i]?.data?.infos
            }
          }
          // console.log(res)
        })
    website_hist(label, page, size, search.value)
        .then(res =>{
          lists.website_list = res.data.result.infos
        })
  }
}
const page_size_change = (val) => {
  page_size.value = val
  upload_news()
}
const router_push = command => {
  router.push(command)
}
const href_url = (url)=>{
  window.open(url, '_blank');
}
const router_url = (id)=>{
  const { href } = router.resolve({
    path:  `/content/${id}`,
  })
  window.open(href, '_blank')
  // router_push({ path: `content/${id}`})
}
const current_page_change = (val) => {
  current_page.value = val
  upload_news()
}
const upload_news = (page = current_page.value,size = page_size.value) =>{
  news_filter({
    order: news_sort.value === '正序' ? 'aes' : 'desc',
    category: submit_list.category_list.concat(query_list.category_list),
    common_labels: submit_list.common_list.concat(query_list.common_list),
    drug_labels: submit_list.drug_list.concat(query_list.drug_list),
    target_labels: submit_list.target_list.concat(query_list.target_list),
    indication_labels: submit_list.indication_list.concat(query_list.indication_list),
    org_labels: submit_list.org_list.concat(query_list.org_list),
    date_type: date_type.value,
    dates: date_list.value,
    page,
    size,
    websites: submit_list.website_list
  })
      .then(res=>{
        news_data.value = res.data.results
        news_count.value = res.data.total
        scrollbar.value.setScrollTop(0)
        // console.log(res)
      })
}
const deopdown_command = (val) => {
  // console.log(val)
  if(val){
    news_sort.value = '正序'
    upload_news()
  } else {
    news_sort.value = '倒序'
    upload_news()
  }
}
const time_from_now = (time) =>{
  dayjs.extend(relativeTime)
  return dayjs(time).fromNow()
}
const tag_type = (tag)=>{
  let type = '#909399'
  if(tag === '临床'){
    type = '#049b39'
  } else if (tag === '会议'){
    type = '#d5ac1c'
  } else if (tag === '市场准入'){
    type = '#c93e10'
  } else if (tag === '政策与法规'){
    type = '#1290d2'
  } else if (tag === '早研'){
    type = '#ab0d6c'
  } else if (tag === '总结报告'){
    type = '#860da8'
  } else if (tag === '投融资'){
    type = '#2619a8'
  } else if (tag === '人事变动'){
    type = '#691919'
  } else if (tag === '不良反应'){
    type = '#0e5950'
  }
  return type
}
const category_name = (val) => {
  if(category_list[val]){
    return category_list[val]
  } else {
    return val
  }
}
const deepEqual = (obj1, obj2) => {
  // 如果是基本类型或者引用相同，则直接比较
  if (obj1 === obj2) {
    return true;
  }

  // 如果其中一个不是对象，或者其中一个是null，则返回false
  if (typeof obj1 !== 'object' || obj1 === null ||
      typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // 获取对象的所有键
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // 如果键的数量不相等，直接返回false
  if (keys1.length !== keys2.length) {
    return false;
  }

  // 检查每个键的值是否相等
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

const news_check = ()=>{
  Object.assign(submit_list, {...check_list})
  current_page.value = 1
  // page_size.value = 20
  upload_news()
}
const dis_list = (val, label)=>{
  if(submit_list[label].includes(val)){
    return true
  } else {
    return false
  }
}
const clear_date = (val) => {
  if(val !=='range'){
    date_list.value = []
    upload_news()
  }
}
const check_labels = (val) => {
  if(val){
    lists.category_list = []
    lists.date_list = []
    lists.common_list = []
    lists.drug_list = []
    lists.target_list = []
    lists.indication_list = []
    lists.org_list = []
    labels_fuzz(val)
        .then(res => {
          // console.log(res)
          res.data.labels.forEach(item => {
            // console.log(item.cate)
            // console.log(item.label)
            lists[item.cate + '_list'].push({
              label: item.label,
              count: null
            })
          });
        })
    website_fuzz(val)
        .then(res => {
          lists.website_list = res.data.result.infos
        })
  } else {
    get_labels('all')
  }
}
const news_fuzzy_query = (data) => {
  // console.log(data)
  query_list.category_list = []
  query_list.common_list = []
  query_list.drug_list = []
  query_list.target_list = []
  query_list.indication_list = []
  query_list.org_list = []
  if(data){
    search.value = data
    query_list[search.value.split(' - ')[0] + '_list'].push(search.value.split(' - ')[1])
    get_labels(search.value.split(' - ')[0] + '_list')
  } else {
    search.value = ''
    get_labels('all')
  }
  current_page.value = 1
  // page_size.value = 20
  upload_news()
}
const clear_all = () =>{
  submit_list.category_list = []
  submit_list.common_list = []
  submit_list.drug_list = []
  submit_list.target_list = []
  submit_list.indication_list = []
  submit_list.org_list = []
  submit_list.website_list = []
  Object.assign(check_list, {...submit_list})
  current_page.value = 1
  upload_news()
}
const handleClose = (val, name) => {
  // console.log(val)
  // console.log(name)
  submit_list[name] = submit_list[name].filter(item => item !== val)
  Object.assign(check_list, {...submit_list})
  current_page.value = 1
  upload_news()
}
const cearte_dialog = ()=>{
  dialogTableVisible.value = true
}
const change_visibel = ({visible,success})=>{
  dialogTableVisible.value = visible
  if(success){
    ElMessage({
      message: '订阅成功',
      type: 'success',
    })
  }
}
const feedback_visibel = ({visible})=>{
  feedback_visible.value = visible
}
const feedback = (data) =>{
  feedback_visible.value = true
  feedback_data.value = data
}
const get_history = (id) => {
  // show_history.value = true
  label_history(id)
      .then(res =>{
        history_data.value = res.data.result
      })
}
const name_website = (id) => {
  let name = ''
  website_name_list.value.forEach((val) => {
    if(val.website_id === id){
      console.log(val)
      name = val.website_name
    }
  })
  return name
}

onMounted(()=>{
  website_fuzz('')
      .then(res => {
        website_name_list.value = res.data.result.infos
      })
  if(search.value){
    query_list[search.value.split(' - ')[0] + '_list'].push(search.value.split(' - ')[1])
  }
  if(sub_id.value){
    get_subscribe_info(sub_id.value)
        .then(res=>{
          // console.log(res)
          check_list.category_list = res.data.category
          check_list.common_list = res.data.common_labels
          check_list.drug_list = res.data.drug_labels
          check_list.target_list = res.data.target_labels
          check_list.indication_list = res.data.indication_labels
          check_list.org_list = res.data.org_labels
          news_check()
        })
    // console.log(sub_id.value)
  } else {
    upload_news()
  }
  get_labels('all')

})

</script>

<style scoped>
.left_main_bar {
  position: relative;
  height: calc(100vh - 60px);
  width: 260px;
  background-color: rgb(244,245,247);
  padding: 8px;
  font-size: 14px;
}

.left_bold{
  position: absolute;
  right: -15px;
  top: 8px;
}
.right_bold{
  position: absolute;
  left: -28px;
  top: 8px;

}
.filter_btn{
  position: absolute;
  right: -31px;
  //top: 130px;
  top: 40%;
  z-index:1;
  height: 70px;
  padding: 11px 8px;
}
.d_none{
  display: none;
}
.m_top_10{
  margin-top: 10px;
}
.hidden{
  width: 0;
  padding: 0;
}
.o_hidden{
  overflow: hidden;
}
:deep(.el-divider--horizontal){
  margin: 8px 0;
}
:deep(.el-collapse-item__wrap){
  background-color: rgb(244,245,247);
}
:deep(.el-collapse-item__header){
  background-color: rgb(244,245,247);
  font-weight: bold;
}
:deep(.el-collapse-item__content){
  padding-bottom: 0;
  padding-right: 8px;
}
:deep(.el-progress-bar){
  width: 100%;
}
:deep(.el-checkbox__label){
  width: 90%;
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d_flex{
  display: flex;
}
.flex_1{
  flex: 1;
}
.hand-cursor {
  cursor: pointer;
}
.tab{
  margin: 5px 0 5px 0;
}
.el-tag{
  margin: 1px 2px;
  cursor: pointer;

}
.pagination{
  display: flex;
  justify-content: center;
}

</style>