export const tab_type_option = [
    {
        value: 'category',
        label: '分类标签',
    },
    {
        value: 'common_labels',
        label: '通用标签',
    },
    {
        value: 'drug_labels',
        label: '药物',
    },
    {
        value: 'target_labels',
        label: '靶点',
    },
    {
        value: 'indication_labels',
        label: '适应症',
    },
    {
        value: 'org_labels',
        label: '机构',
    },
]
export const tag_name = {
    common_labels: '通用',
    drug_labels: '药物',
    target_labels: '靶点',
    indication_labels: '适应症',
    org_labels: '机构'
}
export const common_tag = [
    '早研',
    '临床',
    '市场准入',
    '不良反应',
    '投融资',
    '人事变动',
    '政策及法规',
    '会议',
    '总结报告',
]
export const label_type = {
    news: '新闻',
    category: '分类标签',
    common_labels: '通用标签',
    drug_labels: '药物标签',
    target_labels: '靶点标签',
    indication_labels: '适应症标签',
    org_labels: '机构标签',
}
export const action_type = {
    change: '修改',
    delete: '删除',
    add: '增加',
    drop: '丢弃',
}